@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  min-height: 100vh;
}

.spinner {
  border-top-color: theme("colors.creately.theme.primary");
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.nav-link {
  @apply p-2 my-2 flex space-x-2 rounded-md items-center text-gray-500 hover:text-creately-theme-primary;
}

.nav-link--active {
  @apply bg-creately-theme-primary bg-opacity-20 text-creately-theme-primary;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
